import {Injectable} from '@angular/core';
import moment from 'moment';
import {formatDateTime, getDateByDaysRange, getDatesByHoursRange} from '../operators/time-operator';
import {TimeUnit} from "../models/time.model";


@Injectable({
  providedIn: 'root'
})
export class TimeManagerService {

  constructor() {
  }

  getWeekDayNameByDate(value: any) {
    const date = moment(value);
    const dow = date.day();
    return moment.weekdays(dow);
  }

  dateByFormat(unix: Date | number | string | moment.Moment, format: string) {
    return !unix ? null : formatDateTime(unix);
  }

  convertUnixToDateObject(unix: number) {
    let unixAsDate = moment(unix).toDate();
    return unixAsDate;
  }

  generateMockedDate(startYear: number): any {
    let start = new Date(startYear, 0, 1);
    let end = new Date();
    let randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return this.dateByFormat(randomDate, 'DD-MMM-YYYY HH:mm');
  }

  generateMockedAndFormattedDate(startYear: number): any {
    let start = new Date(startYear, 0);
    let end = new Date();
    let randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return {formatted: this.dateByFormat(randomDate, 'DD-MMM-YYYY HH:mm'), unFormatted: this.dateAsNumbers(randomDate)};
  }

  dateAsNumbers(date: Date) {
    let dateString = moment(date).format('DDMMYYYYHH:mm');
    return dateString;
  }

  generateMockedTimePeriods(index?: number) {
    let mockedTimes: string[] = [
      "10 hours ago",
      "2 months ago",
      "3 days ago",
      "48 hours ago",
      "1 Year ago",
      "4 months and 2 weeks ago",
      "3 weeks and 2 days ago",
      "10 months ago",
      "16 hours ago"
    ]
    let randomIndex: number = Math.floor(Math.random() * mockedTimes.length);
    return mockedTimes[index] ? mockedTimes[index] : mockedTimes[randomIndex];
  }

  generateMockedCron(index?: number): string {
    let mockedCrons: string[] = [
      "12 PM (noon) every day",
      "10:15 AM every day",
      "16:15 PM on the last day of every month",
      "Every November 11th at 11:11 AM",
      "23:55 PM every 5 days",
      "Every minute starting at 2 PM and ending at 2:05 PM, every day",
      "Every 08:00 of every monday",
      "22:00 PM on the first day of every 3 month",
      "Every January 1th at 00:01 AM",
      "16:00 PM every 6 days",
      "Every Sunday at 06:00"
    ]
    let randomIndex: number = Math.floor(Math.random() * mockedCrons.length);
    return mockedCrons[index] ? mockedCrons[index] : mockedCrons[randomIndex];
  }

  /**
   * Return the date with rounded hour (to the early direction)
   */
  getDateWithRoundHour(date: Date): Date {
    date.setHours(date.getHours() - 1 + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
    return date;
  }

  /**
   * Return midnight for given date
   */
  getMidnightOfDate(date: Date): Date {
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  /**
   * Return date with number of hours less
   */
  getHoursBeforeCurrentDate(date: Date, hours: number): Date {
    let currentDateHour = date.getHours();
    let newDate = new Date(date);
    newDate.setHours(currentDateHour - hours, 0, 0, 0);
    return newDate;
  }

  /**
   * Return date with number of hours less
   */
  getHoursAfterCurrentDate(date: Date, hours: number): Date {
    let currentDateHour = date.getHours();
    let newDate = new Date(date);
    newDate.setHours(currentDateHour + hours, 0, 0, 0);
    return newDate;
  }

  /**
   * Return midnight for given date
   */
  getLastSecondOfdate(date: Date): Date {
    let newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }

  /**
   * Return the next day as a string
   * @param dateAsString Current date, in simple string format
   */
  getNextDayAsString(dateAsString: string) {
    const date = new Date(dateAsString);
    let nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return this.convertJSIsoToSpring(nextDay);
    return this.dateByFormat(nextDay, 'YYYY-MM-DD');
  }

  getLast24HoursIsoFormat() {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return {startDate: this.convertJSIsoToSpring(today), endDate: this.convertJSIsoToSpring(yesterday)};
  }

  /**
   * Convert date object to the right Spring format as the BE demands.
   */
  convertJSIsoToSpring(date: Date): string {
    return date.toISOString();
  }

  /**
   * Return Start-End object by calculating days by reange
   */
  getStartEndDatesByRange(daysByRange: number, date: Date, rangeUnit: TimeUnit = TimeUnit.DAYS, isIsoFormat: boolean) {
    if (rangeUnit === 'DAYS') {
      if (isIsoFormat) {
        return {
          start: this.convertJSIsoToSpring(getDateByDaysRange(daysByRange, date, 'subtract')),
          end: this.convertJSIsoToSpring(getDateByDaysRange(daysByRange, date, 'add'))
        };
      } else {
        return {
          start: this.dateByFormat(getDateByDaysRange(daysByRange, date, 'subtract'), 'YYYY-MM-DD'),
          end: this.dateByFormat(getDateByDaysRange(daysByRange, date, 'add'), 'YYYY-MM-DD')
        };
      }
    } else {
      if (isIsoFormat) {
        return {
          start: this.convertJSIsoToSpring(getDatesByHoursRange(daysByRange, date, 'subtract')),
          end: this.convertJSIsoToSpring(getDatesByHoursRange(daysByRange, date, 'add'))
        };
      } else {
        return {
          start: this.dateByFormat(getDatesByHoursRange(daysByRange, date, 'subtract'), 'YYYY-MM-DD'),
          end: this.dateByFormat(getDatesByHoursRange(daysByRange, date, 'add'), 'YYYY-MM-DD')
        };
      }
    }
  }
}
