import { Injectable } from '@angular/core';
import { Auth0Service } from '../../auth0.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Logger, LoggerService } from '../../logger.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Auth0Profile } from 'src/app/auth/IBaseAuthService';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  private http: HttpClient;
  private readonly functionsBaseUrl =
    `https://${environment.firebase.region}-${environment.firebase.projectId}.cloudfunctions.net`;
  private readonly localFunctionsBaseUrl =
    `http://localhost:5000/${environment.firebase.projectId}/${environment.firebase.region}`;
  private logger: Logger;
  private currentUserEmail: string;
  private currentProfile: Auth0Profile;
  private notifyUserDetails: BehaviorSubject<string> = new BehaviorSubject(null);
  private token: firebase.auth.UserCredential;

  userProfileSubscr: Subscription;
  notifyUserDetailsObservable$ = this.notifyUserDetails.asObservable()

  constructor(public afAuth: AngularFireAuth,
    handler: HttpBackend,
    private authService: Auth0Service,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("FirebaseAuthService");
    // Note that we are injecting and using HttpBackend for creating http client
    // which will skip interceptors.
    this.http = new HttpClient(handler);
    this.userProfileSubscr = this.authService.profile$.subscribe((profile) => {
      this.currentProfile = profile;

      if (profile && profile.email) {
        this.currentUserEmail = profile.email;
        this.notifyUserDetails.next(this.currentUserEmail);
      }

      if (profile && environment.useServerless) {
        this.getFirebaseToken(profile);
      }
    })
  }

  getFirebaseToken(profile: Auth0Profile, retry: number = 0) {
    this.authService.getToken().then((token) => {
      this.logger.debug("authenticating with firebase using token : %o profile %o", token, profile);
      this.loginToFirebase(token);
    }, (reason) => {
      this.logger.error(`Couldn't authenticate against firebase for a reason ${reason}`, reason);

      if (retry == 3) {
        window.location.reload();
      } else {
        setTimeout(this.getFirebaseToken.bind(this, profile, retry + 1), 1000);
      }
    })
  }

  loginToFirebase(authToken: string) {
    // First call a function which will get us a firebase jwt token for auth0 one.
    const baseUrl = environment.firebase.localInvocation ? this.localFunctionsBaseUrl : this.functionsBaseUrl;
    const functionUrl = baseUrl + '/http/token'
    this.http.get(functionUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).toPromise().then((firebaseToken: any) => {
      // Now call sign in firebase with firebase jwt token.
      this.afAuth.signInWithCustomToken(firebaseToken.firebaseToken)
        .then(firebaseToken => { this.token = firebaseToken; })
        .catch(reason => {
          console.error("Couldn't log user to firebase %o", reason);
          this.logoutFromFirebase()
          .then(this.getFirebaseToken.bind(this, this.currentProfile))
          .catch(ex => window.location.reload());
        });
    });
  }

  logoutFromFirebase() {
    return this.afAuth.signOut().then(() => {
      this.logger.debug("Logged out from firebase");
    }).catch((reason) => {
      this.logger.error("Couldn't log out from firebase %o", reason);
    });
  }

  destroy() {
    this.userProfileSubscr.unsubscribe()
  }
}
