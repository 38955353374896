import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() searchText: string = '';
  @Input() placeholder: string = '';
  @Output() searchTextChange = new EventEmitter<string>();
  formControl: UntypedFormControl;
  subscription: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.createFormControl();
    this.subscribeToFormControl();
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => {
      if (subsc && !subsc.closed) {
        subsc.unsubscribe();
      }
    });
  }

  createFormControl() {
    this.formControl = this.fb.control(this.searchText ?? '');
  }

  subscribeToFormControl() {
    const subsc = this.formControl.valueChanges.pipe(
      debounceTime(1000),
    ).subscribe(value => {
      this.searchText = value;
      this.searchTextChange.emit(value);
    });
    this.subscription.push(subsc);
  }
}
