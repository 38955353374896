import {Colors} from "../../config/colors";
import {toTitleCase} from "codelyzer/util/utils";
import {SeverityIcons} from "../../config/path";

export enum Severity {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical'
}

export enum SeverityPriority {
  Critical = 1,
  High = 2,
  Medium =  3,
  Low = 4
};

export namespace Severity {
  export function icon(severity: Severity): string {
    switch (severity) {
      case Severity.Critical:
        return SeverityIcons.CRITICAL;
      case Severity.High:
        return SeverityIcons.HIGH;
      case Severity.Medium:
        return SeverityIcons.MEDIUM;
      case Severity.Low:
        return SeverityIcons.LOW;
      default:
        return SeverityIcons.NOT_COMPUTED;
    }
  }
}

export enum LegacySeverity {
    None = -1,
    High = 1,
    Medium = 2,
    Minor = 3,
    Zero = 4,
    Highest = 5,
    Critical = 6
}

export namespace LegacySeverity {
    export function fromString(severity: string | number): LegacySeverity {
        if (typeof severity === "number") {
            console.log("type of severity number value %o", severity)
            return severity;
        }
        return LegacySeverity[severity];
    }

    export function toString(selectedSeverity: LegacySeverity) {
        let severitiesAsArray: string[] = [
            "Critical",
            "High",
            "Medium",
            "Minor"
        ];
        let currentSeverity = severitiesAsArray.find(severity => LegacySeverity[severity] == selectedSeverity)
        if (currentSeverity !== undefined)
            return currentSeverity;
    }

    export function toArray(isLowerCase: boolean = false) {
        let severitiesAsArray: string[] = [
            "Critical",
            "High",
            "Medium",
            "Minor"
        ];
        return isLowerCase ?
            severitiesAsArray.map(word => word) :
            severitiesAsArray;
    }

    export function getSeverityByNumber(severity: number) {
        switch (severity) {
            case LegacySeverity.High:
                return LegacySeverity.High;
            case LegacySeverity.Medium:
                return LegacySeverity.Medium;
            case LegacySeverity.Minor:
                return LegacySeverity.Minor;
            case LegacySeverity.Zero:
                return LegacySeverity.Zero;
            case LegacySeverity.Highest:
                return LegacySeverity.Highest;
            case LegacySeverity.Critical:
                return LegacySeverity.Critical;
            default:
                break;
        }
    }

    export function getColor(severity: string): string {
      switch (severity) {
        case LegacySeverity.toString(LegacySeverity.Critical):
          return Colors.SEVERITY_CRITICAL;
        case LegacySeverity.toString(LegacySeverity.High):
          return Colors.SEVERITY_HIGH;
        case LegacySeverity.toString(LegacySeverity.Medium):
          return Colors.SEVERITY_MEDIUM;
        case LegacySeverity.toString(LegacySeverity.Minor):
          return Colors.SEVERITY_MINOR;
        default:
          return undefined;
      }
    }
}

export enum QoESeverity {
  POOR = 'POOR',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  BEST = 'BEST'
}
export namespace QoESeverity {
  export function toArray(): QoESeverity[] {
    return [
      QoESeverity.POOR,
      QoESeverity.LOW,
      QoESeverity.MEDIUM,
      QoESeverity.HIGH,
      QoESeverity.BEST
    ];
  }

  export function getName(severity: QoESeverity): string {
    return toTitleCase(severity);
  }
}

export class SeverityCount {
  minorCount: number;
  mediumCount: number;
  highCount: number;
  criticalCount: number;
}
