// Global Colors
$blue-global: var(--global-blue);
$red-global: var(--global-red);
$icons-blue-global: #4db2ff;
$material-primaty-button: #2ea1f8;
$pale-text: #828282;
$accent-color: $pale-text;
$warn-color: $red-global;
$list-selection: #f2faff;

//Entity Colors
$organization-color: #8a7dd0;

//Severity colors
$severity-high: #ff8780;
$severity-medium: #f39915;
$severity-minor: #7dd090;
$severity-critical: #000000;

// // This is the opposite to the severity in some sence.
$health-bad-color: $severity-high;
$health-medium-color: $severity-medium;
$health-good-color: $severity-minor;

$operation-chat-height: calc(100vh - 179px);
$security-chat-height: calc(100vh - 118px);

$possitive-color: #2ed47a;
$medium-color: #ffb946;
$negative-color: $red-global;
$neutral-color: #bab8b8;
$no-color: #ffffff;
$side-border-color: #d3d8dd;

$purple-greyish-xxxx-lighter: #F2F4F6;
$purple-greyish-xxx-lighter: #F0F2F5;
$purple-greyish-xx-lighter: #EDEFF3;
$purple-greyish-x-lighter: #E7E9F0;
$purple-greyish-lighter: #DADEE9;
$purple-greyish-darker: #B8C1D8;

$default-border-color: $purple-greyish-x-lighter;

$default-text-color: #333333;
$dark-bottom-border: #393939;
$soft-gray: #c4c4c4;
$disabled-color: rgba(0, 0, 0, 0.38);
$accordion-panel-header-color: #eeeeee;
$accordion-panel-content-color: #fdfcfc;

//Anomaly Category colors:
$devices: #1f78b4;
$packetloss: #58b8f1;
$traffic: #81afff;
$highlatency: #8a7dd0;
$vpnstatus: #a6cee3;
$vpntraffic: #c1d4ed;
$userNo: #7ec19c;

$date-picker-background: rgba(0, 0, 0, 0.04);

//Kpis colors:
$throughput-up: #47bc3b;
$throughput-down: #246b2b;
$traffic-up: #ebbef3;
$traffic-down: #8B42D6;
$loss: #BF6230;
$latency: #FF5900;
$clients: #9da3e0;
$assoc: #FFD86C;
$dns: #65EED7;
$dhcp: #023039;
$auth: #ffa300;
$traffic-prediction: #33bec4;

:root {
  --global-blue: #467fca;
  --global-red: #ff8780;
  --top-menu-background-color: var(--global-blue);
  --top-menu-foreground-color: #ffffff;
  --left-menu-background-color: #264d70;

  --dialog-header-foreground-color: #ffffff;
  --dialog-header-background-color: #193951;
}

.severity-high {
  color: $severity-high;
}

.severity-medium {
  color: $severity-medium;
}

.severity-minor {
  color: $severity-minor;
}

.severity-critical {
  color: $severity-critical;
}
