import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-dropdown-with-text-editor',
  templateUrl: './dropdown-with-text-editor.component.html'
})
export class DropdownWithTextEditorCellRendererComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('inputField') inputField!: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;
  params: any;
  values: any[] = [];
  displayedValues: string[] = [];

  agInit(params: any): void {
    this.params = params;
    this.values = Array.isArray(params.values) ? params.values : [];
    this.displayedValues = this.values.map(item => item?.name);
  }

  ngAfterViewInit(): void {
    this.autoTrigger.openPanel();
    if (this.params?.value) {
      this.inputField.nativeElement.value = this.params.value;
    }
  }

  onOptionSelected(event: any): void {
    const index = event.option._element.nativeElement.getAttribute('data-index');
    this.params.updateRow(this.values[index], this.params.node.rowIndex)
  }

  getValue(): any {
    return this.inputField.nativeElement.value;
  }

  isPopup(): boolean {
    return false;
  }
}
