import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import _ from "lodash";
import { Observable, mergeMap, take, concatMap, combineLatest, switchMap, map, share } from "rxjs";
import { IAppState } from "src/app/store/state/app.state";
import { OpenPortsDisplayService } from "src/app/venues/venues-single-components/venue-open-ports/services/open-ports-display.service";
import { BusinessIntent } from "../../models/business-intent.model";
import { FailedConnection } from "../../models/clients.model";
import { CloudNodeKPIs, CloudLink } from "../../models/cloud-network.model";
import { DevicesHealth, DeviceState } from "../../models/devices.model";
import { EndDeviceType, EndDeviceHealth } from "../../models/end-devices.model";
import { Fabric } from "../../models/fabrics.model";
import { TrafficUnits } from "../../models/kpi.model";
import { TopTrafficSingleDisplayData } from "../../models/legacy-reports.model";
import { OpenPortsAndPolicies, VenueDNatView } from "../../models/open-ports.model";
import { Page } from "../../models/page.model";
import { SingleDevice } from "../../models/single-device.model";
import { SingleLink } from "../../models/single-link.model";
import { BaseSortFilter } from "../../models/sort-filter/base-sort-filter.model";
import { Topology } from "../../models/topology";
import { Venue, VenueData, VenueSyncData, VenueSummary, VenueTopDeviceStatsDto, VenueTopDeviceConnectionTypeData, VenueTopDeviceDomainData, VenueTopClientStatsDto, VenueTopStatsData, VenueTopClientOnlineDevicesData, UpdateVenueRequest } from "../../models/venues.model";
import { DisplayVenueVPN, VenueVPN, VPNStatusTrendDTO } from "../../models/vpn.model";
import { GlobalEntitiesService } from "./global-entities.service";
import { TimeUnit } from "../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class VenuesService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>, private openPortsDisplayService: OpenPortsDisplayService) {
    super(store);
  }

  fetchVenuesByTenantId(tenantId: number, withHealth?: boolean): Observable<Venue[]> {
    return this.http.get<Venue[]>(`tenants/${tenantId}/venues${withHealth ? '?withHealth=true' : ''}`);
  }

  fetchVenues(): Observable<Venue[]> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.fetchVenuesByTenantId(tenantId))
    );
  }

  getVenueMetaData(): Observable<VenueData> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((currentEntitiy) => this.http.get<VenueData>(`venues/${currentEntitiy.id}/kpis/`)));
  }

  getVenueTopology(): Observable<Topology<SingleDevice, SingleLink>> {
    return (this.currentEntity$).pipe(
      take(1),
      mergeMap((currentEntitiy) => this.http.get<Topology<SingleDevice, SingleLink>>(`venues/${currentEntitiy.id}/topology/`))
    );
  }

  getVenueTopologyById(venueId: number): Observable<Topology<SingleDevice, SingleLink>> {
    return this.http.get<Topology<SingleDevice, SingleLink>>(`venues/${venueId}/topology/`);
  }

  getVenueCloudTopology(venueId: number): Observable<Topology<CloudNodeKPIs, CloudLink>> {
    return this.http.get<Topology<CloudNodeKPIs, CloudLink>>(`venues/${venueId}/cloudTopology/`);
  }

  fetchVenueDevicesHealth(sortFilter: BaseSortFilter, timeBack: number, timeUnit: TimeUnit, managedDeviceOption?: any): Observable<Page<DevicesHealth>> {
    sortFilter.sortBy ||= 'criticalIncidentSeverity';
    const managedDevice = managedDeviceOption ? `managedDeviceOption=${managedDeviceOption}&` : '';
    return this.currentVenue$.pipe(
      mergeMap(venue => this.http.get<Page<DevicesHealth>>(sortFilter.appendToURL(`dashboard/health/venues/${venue?.id}/devices-health?${managedDevice}timeBack=${timeBack}&timeUnit=${timeUnit}`)))
    );
  }

  fetchEndDevices(venueId: number, endDeviceType: EndDeviceType[], filterBy: string, timeBack: number, timeUnit: TimeUnit, sortFilter: BaseSortFilter): Observable<Page<EndDeviceHealth>> {
    return this.http.get<Page<EndDeviceHealth>>(sortFilter.appendToURL(`dashboard/health/venues/${venueId}/end-devices-health?endDeviceType=${endDeviceType.join(',')}&filterBy=${filterBy}&timeBack=${timeBack}&timeUnit=${timeUnit}`));
  }

  fetchVenueFabricsById(venueId: number): Observable<Fabric[]> {
    return this.http.get<Fabric[]>(`venues/${venueId}/fabrics/`);
  }

  fetchVenueFabrics(): Observable<Fabric[]> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap(currentEntity => this.fetchVenueFabricsById(currentEntity.id)));
  }

  getVenueSyncStatus(): Observable<VenueSyncData> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((currentEntitiy) => this.http.get<VenueSyncData>(`venues/${currentEntitiy.id}/sync-status/`)));
  }

  syncVenueById(venueId: number) {
    let businessIntents: BusinessIntent = {
      name: 'get_all_venue_data',
      scenario: 'Sync',
      properties: {
        venueId: venueId
      }
    };
    return this.tenantId$.pipe(
      take(1),
      concatMap((tenantId) =>
        this.http.post(`businessIntents/intent/${tenantId}`, businessIntents)
      )
    );
  }

  syncVenue() {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => {
        const businessIntents: BusinessIntent = {
          name: 'get_all_venue_data',
          scenario: 'Sync',
          properties: {
            venueId: currentEntity.id
          }
        };
        return this.http.post(`businessIntents/intent/${tenantId}`, businessIntents)
      }))
  }

  // DEPRECATED
  getVenueFailedClientsConnection(): Observable<Page<FailedConnection>> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((currentEntitiy) => this.http.get<Page<FailedConnection>>(`venues/${currentEntitiy.id}/failed-clients/`)));
  }

  fetchTenantVenuesSummary(): Observable<VenueSummary[]> {
    return this.tenantId$.pipe(
      take(1),
      switchMap((tenantId) =>
        this.http.get<Array<any>>(`tenants/${tenantId}/venues/summary`).pipe(
          map(venues => {
            return venues.map((venue) =>
              new VenueSummary(venue.venueId, venue.venueName, venue.deviceCount, venue.SSIDCount, venue.vlanCount)
            );
          })
        )
      )
    );
  }

  fetchVenuesSummaryByTenantId(tenantId: number): Observable<VenueSummary[]> {
    return this.http.get<Array<any>>(`tenants/${tenantId}/venues/summary`).pipe(
      map(venues => {
        return venues.map((venue) =>
          new VenueSummary(venue.venueId, venue.venueName, venue.deviceCount, venue.SSIDCount, venue.vlanCount)
        );
      })
    );
  }

  getOpenPorts(): Observable<OpenPortsAndPolicies> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((currentEntity) => this.http.get<VenueDNatView>(`policies/nat/venue/${currentEntity.id}`).pipe(map(dNats => {
        const openPortsAndPolicies: OpenPortsAndPolicies = {
          openPortsView: this.openPortsDisplayService.generateOpenPortsView(dNats && dNats.nats ? dNats.nats : []),
          policyView: this.openPortsDisplayService.generateGlobalPoliciesView(dNats && dNats ? dNats.venuePolicies : null)
        }
        return openPortsAndPolicies;
      }))
      )
    )
  }

  fetchCurrentVenueVPNs(sortAndFilter: BaseSortFilter, timeBack: number = 1, timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<DisplayVenueVPN>> {
    sortAndFilter.sortBy = sortAndFilter.sortBy == 'data.secondVpnSite.name' ? 'peerSiteName' : 'usage';

    return this.currentVenue$.pipe(
      take(1),
      mergeMap((currentVenue) => this.fetchVenueVPNs(currentVenue.id, sortAndFilter, timeBack, timeUnit)));
  }

  fetchVenueVPNs(venueId: number, sortAndFilter: BaseSortFilter, timeBack: number = 1, timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<DisplayVenueVPN>> {
    sortAndFilter.sortBy ||= 'criticalIncidentSeverity';

    return this.http.get<Page<DisplayVenueVPN>>(sortAndFilter.appendToURL(`venues/${venueId}/vpns?timeBack=${timeBack}&timeUnit=${timeUnit}`))
      .pipe(map((data: any) => data.map(vpn => ({
        data: ({
          data: _.omit(vpn, ['status', 'traffic']) as unknown as VenueVPN,
          status: vpn.status,
          traffic: vpn.traffic
        })
      }))),
        map((data: any) => ({ data, total: data.length })));
  }

  getVPNSiteStatusTrend(connectionId: number): Observable<VPNStatusTrendDTO<DeviceState>[]> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap((venue) =>
        this.http.get<VPNStatusTrendDTO<DeviceState>[]>(`vpn/venues/${venue.id}/connection/${connectionId}/status/trend`))
    )
  }

  fetchVenueTopDeviceStats(venueId: number, timeBack = 1, timeUnit: TimeUnit = TimeUnit.HOURS, numOfResults = 5): Observable<VenueTopDeviceStatsDto> {
    return this.http
      .get(`venues/${venueId}/stats/devices?features=CONNECTION_TYPE,DOMAIN&timeBack=${timeBack}&timeUnit=${timeUnit.toString()}&numOfResults=${numOfResults}`)
      .pipe(
        map((data: any) => {
          let result = new VenueTopDeviceStatsDto();
          const connectionType = data?.CONNECTION_TYPE as VenueTopDeviceConnectionTypeData[];
          const domain = data?.DOMAIN as VenueTopDeviceDomainData;

          if (connectionType != null)
            result.connectionType = connectionType.map(conn =>
              new TopTrafficSingleDisplayData().init(conn.total_traffic, TrafficUnits.KB, conn.resourceId));

          if (domain != null)
            result.domain = Object.keys(domain).map(prop =>
              new TopTrafficSingleDisplayData().init(domain[prop], null, prop))
              .filter(data => data?.label?.toLowerCase() !== 'www');

          return result;
        }),
        share()
      );
  }

  fetchVenueTopClientStats(venueId: number, timeBack = 1, timeUnit: TimeUnit = TimeUnit.HOURS, numOfResults = 5)
    : Observable<VenueTopClientStatsDto> {
    return this.http
      .get(`venues/${venueId}/stats/online-clients?features=APPLICATION_DISTRIBUTION,MANUFACTURER,TOP_TRAFFIC,CONNECTION_TYPE,ONLINE_DEVICES&timeBack=${timeBack}&timeUnit=${timeUnit.toString()}&numOfResults=${numOfResults}`)
      .pipe(
        map((data: any) => {
          if (data) {
            let result = new VenueTopClientStatsDto();
            const applicationDistribution = data?.APPLICATION_DISTRIBUTION as VenueTopStatsData[];
            const connectionType = data?.CONNECTION_TYPE as VenueTopStatsData[];
            const manufacturer = data?.MANUFACTURER as VenueTopStatsData[];
            const topTraffic = data?.TOP_TRAFFIC as VenueTopStatsData[];
            const onlineDevices = data?.ONLINE_DEVICES as VenueTopClientOnlineDevicesData;

            if (applicationDistribution)
              result.applicationDistribution = applicationDistribution.map(application =>
                new TopTrafficSingleDisplayData().init(application.value, TrafficUnits.KB, application.resourceId));

            if (connectionType)
              result.connectionType = connectionType.map(conn =>
                new TopTrafficSingleDisplayData().init(conn.value, TrafficUnits.KB, conn.resourceId));

            if (manufacturer)
              result.manufacturer = manufacturer.map(mnf =>
                new TopTrafficSingleDisplayData().init(mnf.value, TrafficUnits.KB, mnf.resourceId));

            if (topTraffic)
              result.topTraffic = topTraffic.map(client =>
                new TopTrafficSingleDisplayData().init(client.value, TrafficUnits.KB, client.resourceId));

            if (onlineDevices)
              result.onlineDevices = Object.keys(onlineDevices).map(prop =>
                new TopTrafficSingleDisplayData().init(onlineDevices[prop], null, prop));

            return result;
          }
          return null;
        }),
        share()
      );
  }

  fetchFeatures(venueId: number): Observable<string[]> {
    return this.http.get<string[]>(`venues/${venueId}/features`);
  }

  fetchCurrentFeatures(): Observable<string[]> {
    return this.currentVenue$.pipe(
      mergeMap(venue => this.fetchFeatures(venue.id))
    );
  }

  updateVenue(id: number, payload: UpdateVenueRequest): Observable<void> {
    return this.http.put<void>(`venues/${id}/name`, payload);
  }
}
