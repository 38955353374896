<ng-container *ngIf="syncData">
    <div>
        <span><strong>{{'data.VENUES.LAST_SYNC' | translate}} {{": "}}</strong></span>
        <span> {{formattedSyncDate}}
        </span>
    </div>
    <div>
        <span><strong>{{('data.VENUES.SYNC_STATUS' | translate)}}
                {{": "}}</strong></span>
        <span>{{formattedSyncStatus}}</span>
        <img [disabled]="!isSyncFinished" class="icon-button" [class.disabled]="!isSyncFinished" src="assets/media/netop/topology/sync.svg" alt="Sync Now" matTooltip="Sync Now" (click)="isSyncFinished? syncVenue(): null" />
    </div>
</ng-container>
