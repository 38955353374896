import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterData, FiltersBar } from './models/filters-bar.model';
import { FormFieldsType } from '../../modals/models/form-actions.model';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.scss']
})
export class FiltersBarComponent implements OnInit {
  @Input() filters: FiltersBar<any, any>[];
  @Input() cssColContainer: string;
  @Output() filterSelected: EventEmitter<any> = new EventEmitter();
  inputFormControl: UntypedFormControl = new UntypedFormControl('', Validators.minLength(3));
  searchFormControl: UntypedFormControl = new UntypedFormControl('');
  searchInputDisplay = (value: FilterData<any, any>) => value?.name;
  multiSelectFormControls: { [name: string]: UntypedFormControl } = {};
  filterFieldType = FormFieldsType;
  selected: FilterData<any, any>;
  multiSelectedState: FilterData<any, any>[];
  form: FormGroup;

  constructor() {
    this.subscribeToFormControl();
    this.subscribeToSearchFormControl();
  }

  ngOnInit() {
    if (this.filters) {
      let fields = {}
      this.filters.forEach(filter => {
        if (filter.fieldType == FormFieldsType.Multi_Select) {
          fields[filter.name] = new FormControl([filter.data[0]], { initialValueIsDefault: true });
        }
      });
      this.form = new FormGroup(fields);
      this.setDefaultValueForSingleSelection();
      this.setDefaultValueForMultiSelection();
    }
  }

  subscribeToFormControl() {
    this.subscribeToInputFormControl();
  }

  onSingleSelectionChanged(value: any) {
    this.filterSelected.emit([value]);
  }

  onMultiSelectionChanged(event: MatSelectChange) {
    if (!this.multiSelectedState && event?.value) {
      this.multiSelectedState = event.value;
    }

    const defaultValue = event.source.options.first.value,
      defaultValueSelected = event.value.find(filter => filter.isFakeItem),
      defaultItemWasAlreadySelected = this.multiSelectedState.find(filter => filter.isFakeItem)?.isSelected;

    if (event.value.length == 0 || (defaultValueSelected && !defaultItemWasAlreadySelected)) {
      this.multiSelectedState = [defaultValue];
    }
    else if (event.value.length > 1 && defaultItemWasAlreadySelected) {
      this.multiSelectedState = event.value.filter(filter => !filter.isFakeItem);
    }
    else this.multiSelectedState = event.value;

    if (this.multiSelectedState.length > 0) {
      this.multiSelectFormControl(this.multiSelectedState[0].filterName).setValue(this.multiSelectedState);
    }
    this.onOpenChanged(false)
  }

  multiSelectFormControl(name: string) {
    return this.form.controls[name];
  }

  setDefaultValue(a: FilterData<any, any>, b: FilterData<any, any>) {
    return a.isSelected;
  }

  private subscribeToInputFormControl() {
    this.inputFormControl.valueChanges.pipe(debounceTime(400)).subscribe(changes => {
      if (this.inputFormControl.valid) {
        let inputFilter = this.filters.find(filter => filter.fieldType == FormFieldsType.INPUT);
        let filterData: FilterData<string, string> = {
          id: 1,
          name: inputFilter.name,
          type: inputFilter.fieldType.toString(),
          data: changes,
          filterName: inputFilter.name
        }
        this.filterSelected.emit([filterData]);
      }
    });
  }

  private subscribeToSearchFormControl() {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(400)
    ).subscribe(changes => {
      this.filterSelected.emit([changes]);
    });
  }

  onOpenChanged(isDropdownOpen: boolean) {
    if (!isDropdownOpen && this.multiFilter) {
      Object.keys(this.form.controls).forEach(name => {
        const multiSelectFormControl = this.form.controls[name];
        if (multiSelectFormControl && multiSelectFormControl.valid && multiSelectFormControl.value && multiSelectFormControl.value.length > 0) {
          const data = multiSelectFormControl.value;
          let filterData: FilterData<Array<any>, string> = {
            id: 2,
            name: data[0].name,
            type: data[0].type,
            data: data,
            filterName: data[0].filterName
          }
          if (this.multiFilter.fieldType == FormFieldsType.Multi_Select) {
            filterData.data = filterData.data?.map(entry => entry.data);
          }
          this.filterSelected.emit([filterData]);
        }
      });
    }
  }

  getFilteredData(filter: FiltersBar<any, any>): FilterData<any, any>[] {
    return filter.searchable && typeof this.searchFormControl.value == 'string' && this.searchFormControl.value && this.searchFormControl.value.length > 0
      ? filter.data.filter(datum => datum.name
        ?.toLowerCase()
        ?.includes(this.searchFormControl.value?.toLowerCase()))
      : filter.data;
  }

  getTriggerValue(filter: FiltersBar<any, any>) {
    return this.multiSelectFormControl(filter.name)?.value ? this.multiSelectFormControl(filter.name).value[0]?.name : '';
  }

  get multiFilter(): FiltersBar<any, any> {
    return this.filters && this.filters.length > 0 && this.filters.find(filter => filter.fieldType == FormFieldsType.Multi_Select) !== undefined ?
      this.filters.find(filter => filter.fieldType == FormFieldsType.Multi_Select)
      : undefined;
  }

  private setDefaultValueForSingleSelection() {
    let changed = false;
    this.filters?.forEach(filter => {
      if (filter.fieldType == FormFieldsType.SELECT) {
        filter.data?.forEach(value => {
          if (value.isSelected) {
            this.onSingleSelectionChanged(value);
          }
        });
      }
    });
  }

  private setDefaultValueForMultiSelection() {
    if (this.multiSelectFormControls) {
      Object.keys(this.form.controls).forEach(name => {
        const multiSelectFormControl = this.form.controls[name];
        const filter = this.filters.find(f => f.name == name);
        const filterSelectAllOption = filter?.data.find(datum => datum.isSelected && datum.filterName == name);
        if (filterSelectAllOption !== undefined) {
          multiSelectFormControl.setValue(filter?.data?.filter(entry => entry.isSelected && entry.filterName == name));
        }
        this.onOpenChanged(false);
      });
    }
  }
}
