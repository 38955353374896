import {Injectable} from '@angular/core';
import { MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../modals/snack-bar/snack-bar.component';
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DisplayErrorService {

  constructor(private toastService: MatSnackBar) {
  }

  generateErrorText(errorResponse: HttpErrorResponse): string {
    let errorMessage;

    if (!errorResponse?.error?.message) return;

    if (errorResponse.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${errorResponse.error.message}`;
    } else {
      // server-side error
      if (typeof errorResponse.error === "object") {
        errorMessage = `Status: ${errorResponse.error.status} - ${errorResponse.error.error}`;
        if (errorResponse.error.message) {
          errorMessage += `. Message: ${errorResponse.error.message}`
        }
      } else {
        let message = errorResponse.error;
        if (errorResponse.error === '404 Token not found') {
          message = 'Invitation seems to be invalid or expired. Please contact system administer';
        }
        errorMessage = `Error Message: ${message}`;
      }
    }
    return errorMessage;
  }

  openMessage(error: string | { status: number, message: string }) {

    if (!error) return;
    this.toastService.openFromComponent(SnackBarComponent,
      {
        data: error,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
        panelClass: 'mat-snack-bar-alert'
      });
  }
}
