import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';


@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  dialogData: any;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data) {
        if (typeof data === 'object') {
        this.dialogData = JSON.stringify(data, null, 2);
      } else {
        this.dialogData = data;
      }
  }

}
